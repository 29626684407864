import React from 'react';

import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <p>Ups! Diese Seite existiert leider nicht.</p>
  </Layout>
);

export default NotFoundPage;
